html,
body {
  font-family: "Poppins", sans-serif;
  width: 100%;
  height: 100%;
  justify-content: center;
  color: #000;
}

.ads {
  width: 90%;
  max-width: 70rem;
  list-style: none;
  margin: 2rem auto;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(3, minmax(20rem, 1fr));
  gap: 1rem;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-left: 40px;
}

.column {
  flex: 50%;
  padding: 5px;
}

.bullet {
  font-size: 30px;
  text-transform: uppercase;
  line-height: 100%;
  color: #056939;
  padding: 5px;
}

.paragraph {
  font-size: 24px;
  line-height: 1.3;
  color: #3d3e3f;
  width: 100%;
  padding: 10px;
}

.label {
  color: #056939;
}

.tkoselect {
  min-width: 125px;
}

.tkoselect select {
  appearance: none;
  width: 90%;
  font-size: 20px;
  padding: 0.675em 6em 0.675em 1em;
  background-color: #f6f6f2;
  border: 1px solid #caced1;
  border-radius: 0.25rem;
  color: #000;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .ads {
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  }
  .paragraph {
    font-size: 20px;
  }

  .row {
    flex-direction: column;
  }

  .tkoselect select {
    font-size: 16px;
  }
}
