.aditem {
  background-color: #f6f6f2;
  border-radius: 1rem;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
}

.aditem article {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.aditem img {
  width: auto;
  max-height: 250px;
}

.aditem h3 {
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #056939;
  margin: 0.75rem 0;
}

.desc {
  margin: 1rem;
  font-size: 20px;
}

.town {
  font-size: 24px;
  font-weight: 900;
}

.phone {
  display: inline-block;
  background-color: #1d1a16;
  color: #ffde17;
  font-size: 24px;
  font-weight: bold;
  padding: 0.5rem 2rem;
  margin: 0;
  border-radius: 4px;
}
