@import url("https://fonts.googleapis.com/css?family=Poppins");

html,
body {
  font-family: "Poppins", sans-serif;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.center {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #aeaba7;
}

.title {
  color: #056939;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 900;
  margin-left: 10px;
  line-height: 100%;
}

.paragraph {
  font-size: 24px;
  line-height: 1.3;
  color: #3d3e3f;
  margin-left: 20px;
  width: 75%;
  padding: 5px;
}

.tabledata {
  font-size: 20px;
  line-height: 1.1;
  color: #3d3e3f;
  padding: 2px;
}

.highlight {
  list-style: square;
  margin-left: 40px;
  font-size: 24px;
  line-height: 1.3;
  color: #3d3e3f;
  padding-bottom: 10px;
}

.bullet {
  font-size: 30px;
  text-transform: uppercase;
  line-height: 100%;
  color: #056939;
  padding: 2px;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.column {
  flex: 50%;
  padding: 5px;
}

img {
  width: 100%;
  height: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

tr,
td {
  border: 1px solid;
  padding: 5px;
}

.theader {
  background-color: #1d1a16;
  color: #ffde17;
  text-transform: uppercase;
  font-size: 24px;
  padding: 10px;
  line-height: 1.1;
  border-color: #1d1a16;
}

@media screen and (max-width: 600px) {
  .row {
    flex-direction: column;
  }
}
